const FONTS = [
    'Anton',
    'Monserrat',
    'Open Sans',
    'Oswald',
    'Oxygen',
    'Petrona',
    'PT Sans',
    'Ubuntu',
    'Unbounded',
    'Manrope',
    'Roboto',
    'Barlow Semi Condensed',
    'Fira Sans',
];

export default {
    font_family_head: {
        id: null,
        position: 60,
        type: 'select',
        component: 'Select',
        value: 'Monserrat',
        options: {
            variants: FONTS,
            items: FONTS,
            labelTrans: 'settings.styles.font_family_head',
        },
    },
    font_family_body: {
        id: null,
        position: 61,
        type: 'select',
        component: 'Select',
        value: 'Monserrat',
        options: {
            variants: FONTS,
            items: FONTS,
            labelTrans: 'settings.styles.font_family_body',
        },
    },
    font_style_head: {
        id: null,
        position: 62,
        type: 'font',
        value: '',
        options: {},
    },
    font_style_body: {
        id: null,
        position: 63,
        type: 'font',
        value: '',
        options: {},
    },
    content_width: {
        id: null,
        position: 64,
        type: 'select',
        component: 'Select',
        value: '1170px',
        options: {
            variants: [
                { text: '1170px', value: '1170px' },
                { text: '1280px', value: '1280px' },
                { text: '1360px', value: '1360px' },
                { text: '1440px', value: '1440px' },
            ],
            items: [
                { text: '1170px', value: '1170px' },
                { text: '1280px', value: '1280px' },
                { text: '1360px', value: '1360px' },
                { text: '1440px', value: '1440px' },
            ],
            labelTrans: 'settings.styles.content_width',
        },
    },
    border_radius_block: {
        id: null,
        position: 70,
        type: 'number',
        component: 'InputNumberAndRange',
        value: 16,
        options: {
            labelTrans: 'settings.styles.border_radius_block',
            min: 0,
            max: 30,
        },
    },
    border_radius_btn: {
        id: null,
        position: 70,
        type: 'number',
        component: 'InputNumberAndRange',
        value: 8,
        options: {
            labelTrans: 'settings.styles.border_radius_btn',
            min: 0,
            max: 25,
        },
    },
    border_radius_input: {
        id: null,
        position: 70,
        type: 'number',
        component: 'InputNumberAndRange',
        value: 8,
        options: {
            labelTrans: 'settings.styles.border_radius_input',
            min: 0,
            max: 25,
        },
    },
    scheme: {
        id: null,
        position: 0,
        type: 'select',
        component: 'Select',
        value: 'Scheme 1',
        options: {
            variants: [
                { text: 'Theme 1', value: 'Scheme 1' },
                { text: 'Theme 2', value: 'Scheme 2' },
                { text: 'Theme 3', value: 'Scheme 3' },
                { text: 'Theme 4', value: 'Scheme 4' },
                { text: 'Theme 5', value: 'Scheme 5' },
                { text: 'Theme 6', value: 'Scheme 6' },
                { text: 'Theme 7', value: 'Scheme 7' },
                { text: 'Theme 8', value: 'Scheme 8' },
                { text: 'Theme 9', value: 'Scheme 9' },
                { text: 'Theme 10', value: 'Scheme 10' },
                { text: 'Theme 11', value: 'Scheme 11' },
                { text: 'Theme Custom', value: 'Custom' },
            ],
            items: [
                { text: 'Theme 1', value: 'Scheme 1' },
                { text: 'Theme 2', value: 'Scheme 2' },
                { text: 'Theme 3', value: 'Scheme 3' },
                { text: 'Theme 4', value: 'Scheme 4' },
                { text: 'Theme 5', value: 'Scheme 5' },
                { text: 'Theme 6', value: 'Scheme 6' },
                { text: 'Theme 7', value: 'Scheme 7' },
                { text: 'Theme 8', value: 'Scheme 8' },
                { text: 'Theme 9', value: 'Scheme 9' },
                { text: 'Theme 10', value: 'Scheme 10' },
                { text: 'Theme 11', value: 'Scheme 11' },
                { text: 'Theme Custom', value: 'Custom' },
            ],
            labelTrans: 'settings.styles.scheme',
        },
    },
    action_primary: {
        id: null,
        position: 11,
        type: 'color',
        component: 'color-picker',
        value: '#5A6794',
        options: {
            labelTrans: 'settings.styles.action_primary',
        },
    },
    action_secondary: {
        id: null,
        position: 15,
        type: 'color',
        component: 'color-picker',
        value: '#8794C0',
        options: {
            labelTrans: 'settings.styles.action_secondary',
        },
    },

    text_main: {
        id: null,
        position: 1,
        type: 'color',
        value: '#020305',
        options: {},
    },
    text_body1: {
        id: null,
        position: 2,
        type: 'color',
        value: '#343A47',
        options: {},
    },
    text_body2: {
        id: null,
        position: 3,
        type: 'color',
        value: '#4A5264',
        options: {},
    },
    text_accent: {
        id: null,
        position: 4,
        type: 'color',
        value: '#747DAA',
        options: {},
    },
    text_neutral: {
        id: null,
        position: 5,
        type: 'color',
        value: '#D7D9E1',
        options: {},
    },

    text_main_inverted: {
        id: null,
        position: 6,
        type: 'color',
        value: '#FFFFFF',
        options: {},
    },
    text_body1_inverted: {
        id: null,
        position: 7,
        type: 'color',
        value: '#E5E7F1',
        options: {},
    },
    text_body2_inverted: {
        id: null,
        position: 8,
        type: 'color',
        value: '#D7DCE9',
        options: {},
    },
    text_accent_inverted: {
        id: null,
        position: 9,
        type: 'color',
        value: '#B3B9C7',
        options: {},
    },
    text_neutral_inverted: {
        id: null,
        position: 10,
        type: 'color',
        value: '#959AA6',
        options: {},
    },

    action_primary_hover: {
        id: null,
        position: 12,
        type: 'color',
        value: '#8794C0',
        options: {},
    },
    action_primary_pressed: {
        id: null,
        position: 13,
        type: 'color',
        value: '#404969',
        options: {},
    },
    action_primary_disabled: {
        id: null,
        position: 14,
        type: 'color',
        value: '#DCDEE5',
        options: {},
    },

    action_secondary_hover: {
        id: null,
        position: 16,
        type: 'color',
        value: '#7894F1',
        options: {},
    },
    action_secondary_pressed: {
        id: null,
        position: 17,
        type: 'color',
        value: '#404969',
        options: {},
    },
    action_secondary_disabled: {
        id: null,
        position: 18,
        type: 'color',
        value: '#D7D9E1',
        options: {},
    },

    header_bg: {
        id: null,
        position: 19,
        type: 'color',
        value: '#ffffff',
        options: {},
    },
    header_bg_top_bar: {
        id: null,
        position: 19,
        type: 'color',
        value: '',
        options: {},
    },
    header_bg_mobile: {
        id: null,
        position: 19,
        type: 'color',
        value: '',
        options: {},
    },
    header_main_menu_bg: {
        id: null,
        position: 19,
        type: 'color',
        value: '#30385A',
        options: {},
    },
    header_main_menu_dropdown_bg: {
        id: null,
        position: 19,
        type: 'color',
        value: '#505773',
        options: {},
    },
    action_neutral_light: {
        id: null,
        position: 19,
        type: 'color',
        value: '#B9BCCB',
        options: {},
    },
    action_neutral_light_hover: {
        id: null,
        position: 20,
        type: 'color',
        value: '#8794C0',
        options: {},
    },
    action_neutral_light_pressed: {
        id: null,
        position: 21,
        type: 'color',
        value: '#404969',
        options: {},
    },
    action_neutral_light_disabled: {
        id: null,
        position: 22,
        type: 'color',
        value: '#D7D9E1',
        options: {},
    },

    action_neutral_medium: {
        id: null,
        position: 23,
        type: 'color',
        value: '#8794C0',
        options: {},
    },
    action_neutral_medium_hover: {
        id: null,
        position: 24,
        type: 'color',
        value: '#849BE8',
        options: {},
    },
    action_neutral_medium_pressed: {
        id: null,
        position: 25,
        type: 'color',
        value: '#636C8C',
        options: {},
    },
    action_neutral_medium_disabled: {
        id: null,
        position: 26,
        type: 'color',
        value: '#E7E8E8',
        options: {},
    },

    action_neutral_dark: {
        id: null,
        position: 27,
        type: 'color',
        value: '#404969',
        options: {},
    },
    action_neutral_dark_hover: {
        id: null,
        position: 28,
        type: 'color',
        value: '#5A6794',
        options: {},
    },
    action_neutral_dark_pressed: {
        id: null,
        position: 29,
        type: 'color',
        value: '#1F2333',
        options: {},
    },
    action_neutral_dark_disabled: {
        id: null,
        position: 30,
        type: 'color',
        value: '#D7D9E1',
        options: {},
    },

    action_system: {
        id: null,
        position: 31,
        type: 'color',
        value: '#D96E95',
        options: {},
    },
    action_system_hover: {
        id: null,
        position: 32,
        type: 'color',
        value: '#D5628B',
        options: {},
    },
    action_system_pressed: {
        id: null,
        position: 33,
        type: 'color',
        value: '#CE4A79',
        options: {},
    },
    action_system_disabled: {
        id: null,
        position: 34,
        type: 'color',
        value: '#B3B9C7',
        options: {},
    },

    neutral1: {
        id: null,
        position: 35,
        type: 'color',
        value: '#1C2135',
        options: {},
    },
    neutral2: {
        id: null,
        position: 36,
        type: 'color',
        value: '#30385A',
        options: {},
    },
    neutral3: {
        id: null,
        position: 37,
        type: 'color',
        value: '#505773',
        options: {},
    },
    neutral4: {
        id: null,
        position: 38,
        type: 'color',
        value: '#D3CAD3',
        options: {},
    },
    neutral5: {
        id: null,
        position: 39,
        type: 'color',
        value: '#E8E9EE',
        options: {},
    },
    neutral6: {
        id: null,
        position: 40,
        type: 'color',
        value: '#F6F7F8',
        options: {},
    },
    neutral7: {
        id: null,
        position: 41,
        type: 'color',
        value: '#FFFFFF',
        options: {},
    },

    success: {
        id: null,
        position: 42,
        type: 'color',
        value: '#7DBB95',
        options: {},
    },
    success_bg: {
        id: null,
        position: 43,
        type: 'color',
        value: '#EFF5F1',
        options: {},
    },
    warning: {
        id: null,
        position: 44,
        type: 'color',
        value: '#F0B748',
        options: {},
    },
    warning_bg: {
        id: null,
        position: 45,
        type: 'color',
        value: '#FFF9EE',
        options: {},
    },
    error: {
        id: null,
        position: 46,
        type: 'color',
        value: '#D96E95',
        options: {},
    },
    error_bg: {
        id: null,
        position: 47,
        type: 'color',
        value: '#F8EAEF',
        options: {},
    },

    accent1: {
        id: null,
        position: 48,
        type: 'color',
        value: '#DDC178',
        options: {},
    },
    accent1_bg: {
        id: null,
        position: 49,
        type: 'color',
        value: '#FFF7E3',
        options: {},
    },
    accent2: {
        id: null,
        position: 50,
        type: 'color',
        value: '#F3E381',
        options: {},
    },
    accent2_bg: {
        id: null,
        position: 51,
        type: 'color',
        value: '#FFFBE5',
        options: {},
    },
    accent3: {
        id: null,
        position: 52,
        type: 'color',
        value: '#00B08D',
        options: {},
    },
    accent3_bg: {
        id: null,
        position: 53,
        type: 'color',
        value: '#E0FFF9',
        options: {},
    },
    accent4: {
        id: null,
        position: 54,
        type: 'color',
        value: '#7D76DF',
        options: {},
    },
    accent4_bg: {
        id: null,
        position: 55,
        type: 'color',
        value: '#E7E6F9',
        options: {},
    },

    whatsapp: {
        id: null,
        position: 56,
        type: 'color',
        value: '#49C15C',
        options: {},
    },
};
