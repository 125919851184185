export default {
    styles: {
        value: 'Стили',
        color_scheme: 'Цветовая схема',
        accent: 'Accent',
        accent_secondary: 'Accent secondary',
        action_error: 'Action error',
        action_error_bg: 'Action error bg',
        action_error_hover: 'Action error hover',
        action_error_pressed: 'Action error pressed',
        action_error_text: 'Action error text',
        action_neutral: 'Action neutral',
        action_neutral_disabled: 'Action neutral disabled',
        action_neutral_hover: 'Action neutral hover',
        action_neutral_pressed: 'Action neutral pressed',
        action_neutral_text: 'Action neutral text',
        action_primary: 'Action primary',
        action_primary_disabled: 'Action primary disabled',
        action_primary_hover: 'Action primary hover',
        action_primary_pressed: 'Action primary pressed',
        action_primary_text: 'Action primary text',
        action_secondary: 'Action Secondary',
        action_secondary_disabled: 'Action secondary disabled',
        action_secondary_hover: 'Action secondary hover',
        action_secondary_pressed: 'Action secondary pressed',
        action_secondary_text: 'Action secondary text',
        // action_neutral_light: 'Action neutral light',
        // action_neutral_light_disabled: 'Action neutral light disabled',
        // action_neutral_light_hover: 'Action neutral light hover',
        // action_neutral_light_pressed: 'Action neutral light pressed',
        action_neutral_light: 'Сolor of text',
        action_neutral_light_disabled: 'Сolor of border',
        action_neutral_light_hover: 'Сolor of hover text ',
        action_neutral_light_pressed: 'Сolor of active text',
        // action_neutral_medium: 'Action neutral medium',
        // action_neutral_medium_disabled: 'Action neutral medium disabled',
        // action_neutral_medium_hover: 'Action neutral medium hover',
        // action_neutral_medium_pressed: 'Action neutral medium pressed',
        action_neutral_medium: 'Form item border',
        action_neutral_medium_disabled: 'Form item border disabled',
        action_neutral_medium_hover: 'Form item border active',
        action_neutral_medium_pressed: 'Form item border pressed',
        action_neutral_dark: 'Action neutral dark',
        action_neutral_dark_disabled: 'Action neutral dark disabled',
        action_neutral_dark_hover: 'Action neutral dark hover',
        action_neutral_dark_pressed: 'Action neutral dark pressed',
        action_success: 'Action success',
        action_success_bg: 'Action success bg',
        action_success_hover: 'Action success hover',
        action_success_pressed: 'Action success pressed',
        action_success_text: 'Action success text',
        action_warn: 'Action warn',
        action_warn_bg: 'Action warn bg',
        action_warn_hover: 'Action warn hover',
        action_warn_pressed: 'Action warn pressed',
        action_warn_text: 'Action warn text',
        color_base_invert: 'color base invert',
        color_bg_footer: 'color bg footer',
        color_bg_header: 'color bg header',
        color_bg_main: 'color bg main',
        color_bg_section: 'color bg section',
        color_bg_section_2: 'color bg section 2',
        color_bg_separator: 'color bg separator',
        color_disable: 'color disable',
        color_heading: 'color heading',
        color_heading_invert: 'color heading invert',
        color_hint: 'color hint',
        color_hint_invert: 'color hint invert',
        color_text: 'color text',
        color_text_footer: 'color text footer',
        color_text_invert: 'color text invert',
        color_text_secondary: 'color text secondary',
        color_text_secondary_invert: 'color text secondary invert',
        color_whatsapp: 'color whatsapp',
        color_whatsapp_hover: 'color whatsapp hover',
        color_whatsapp_text: 'color whatsapp text',
        // text_main: 'Text Main',
        text_main: 'Color of headline',
        text_body1: 'Main color of the content text',
        text_body2: 'Additional color of the content text',
        // text_accent: 'Text Accent',
        // text_neutral: 'Text Neutral',
        text_main_inverted: 'Color of headline in dark section',
        text_body1_inverted: 'Main color of the content text in dark section',
        text_body2_inverted: 'Additional color of the content text in dark section',
        // text_accent_inverted: 'Text Accent Inverted',
        // text_neutral_inverted: 'Text Neutral Inverted',
        accent1: 'Label text color  1',
        accent1_bg: 'Label background color 1',
        accent2: 'Label text color 2',
        accent2_bg: 'Label background color 2',
        accent3: 'Label text color 3',
        accent3_bg: 'Label background color 3',
        accent4: 'Label text color 4',
        accent4_bg: 'Label background color 4',
        success: 'Success color',
        success_bg: 'Success Background color',
        warning: 'Warning color',
        warning_bg: 'Warning Background color',
        error: 'Error color',
        error_bg: 'Error Background',
        neutral1: 'Background color 1',
        neutral2: 'Background color 2',
        neutral3: 'Background color 3',
        neutral4: 'Background color 4',
        neutral5: 'Background color 5',
        neutral6: 'Background color 6',
        neutral7: 'Background color 7',
        whatsapp: 'WhatsApp',
        content_width: 'Ширина контента',
        border_radius_block: 'Радиус блоков',
        border_radius_btn: 'Радиус кнопок',
        border_radius_input: 'Радиус полей',
        header_bg: 'Header background',
        header_bg_top_bar: 'Header background Top bar',
        header_bg_mobile: 'Header background mobile',
        header_main_menu_bg: 'Main menu background',
        header_main_menu_dropdown_bg: 'Main menu dropdown background',
    },
    common: {
        value: 'Общее',
        description: 'Описание',
        address: 'Адрес',
        emails: 'Emails',
        phones: 'Телефоны',
        sitename: 'Название сайта',
        showAccount: 'Показывать личный кабинет',
        whatsapps: 'WhatsApps',
        workTime: 'Время работы',
        privacyPolicyLink: 'Политика конфиденциальности',
        favicon: 'Favicon',
        sharedImage: 'Shared Image',
        phoneShowOnMobileHeader: 'Показывать телефон в мобильной шапке',
        telegramShowOnMobileHeader: 'Показывать Telegram в мобильной шапке',
        whatsappShowOnMobileHeader: 'Показывать Whatsapp в мобильной шапке',
        emailShowOnMobileHeader: 'Показывать почту в мобильной шапке',
        phoneShowOnMobileMenu: 'Показывать телефон в мобильном меню',
        telegramShowOnMobileMenu: 'Показывать Telegram в мобильном меню',
        whatsappShowOnMobileMenu: 'Показывать Whatsapp в мобильном меню',
        emailShowOnMobileMenu: 'Показывать почту в мобильном меню',
        phoneShowOnWidget: 'Показывать телефон на мобильном виджете',
        telegramShowOnWidget: 'Показывать Telegram на мобильном виджете',
        whatsappShowOnWidget: 'Показывать WhatsApp на мобильном виджете',
        whatsappShowOnWidgetDesktop: 'Показывать WhatsApp на десктопном виджете',
        whatsappMessage: 'Сообщение в Whatsapp',
        type: 'Тип сайта',
        types: {
            title: 'Тип сайта',
            ecommerce: 'Интернет магазин',
            service: 'Сайт услуг',
            serviceCart: 'Сайт услуг c корзиной',
        },
        phonesShowOnContactsHeader: 'Показывать телефон в контатах, в шапке',
        whatsappShowOnContactsHeader: 'Показывать WhatsApp в контатах, в шапке',
        emailsShowOnContactsHeader: 'Показывать почту в контатах, в шапке',
        telegramShowOnContactsHeader: 'Показывать Telegram в контатах, в шапке',
    },
    socials: {
        value: 'Социальные сети',
        facebook: 'Facebook',
        instagram: 'Instagram',
        twitter: 'X',
        linkedIn: 'Linked In',
        medium: 'Medium',
        telegram: 'Телеграм',
        tiktok: 'TikTok',
        vk: 'ВКонтакте',
    },
    header: {
        value: 'Шапка',
        template: 'Шаблон',
        contactsSize: 'Контакты',
        contactsTextSize: 'Размер шрифта в контактах, px',
        logo: 'Логотип',
        logoMobile: 'Логотип на мобильных устройствах',
        logoAlt: 'alt-текст логотипа',
        menu: 'Основное меню',
        menuOptions: 'Main Menu options',
        menuSubOptions: 'Main Sub Menu options',
        menuOptionsTextSize: 'Размер текста, px',
        menuOptionsTextUppercase: 'Заглавными буквами',
        menuOptionsTextColor: 'Цвет теста',
        menuInfo: 'Верхнее меню',
        menuInfoOptions: 'Опции верхнего меню',
        contactsStyles: 'Стили контактов',
        sendEnquiry: 'Кнопк отправки заявки',
        showSearchMobile: 'Показывать поиск на мобильном',
    },
    footer: {
        value: 'Подвал',
        template: 'Шаблон',
        templates: {
            1: 'Template 1',
            2: 'Template 2',
            3: 'Template 3',
            4: 'Template 4',
        },
        background: 'Фон',
        logo: 'Логотип',
        logoAlt: 'Alt-текст для логотипа',
        copywrite: 'Копирайт',
        footerText: 'Текст',
        menuOptions: 'Footer Menu options',
        menuSubOptions: 'Footer Sub Menu options',
        menuOptionsTextSize: 'Размер текста, px',
        menuOptionsTextUppercase: 'Заглавными буквами',
        menuOptionsTextColor: 'Цвет теста',
        menuColumns: 'Кол-во колонок в меню',
        menu: 'Меню в подвале',
    },
    ecommerce: {
        currency: 'Валюта',
        value: 'Ecommerce',
        template: 'Шаблон страницы продукта',
        templates: {
            1: 'Template 1',
            2: 'Template 2',
            3: 'Template 3',
            4: 'Template 4',
        },
        templateListing: 'Шаблон карточки продукта в списке',
        itemPerPage: 'Количество продуктов на странице',
        defaultColumns: 'Элементов в ряд',
        previewSizeInListing: 'Размер картинки в списке (px)',
        previewCustomSizeInListing: {
            title: 'Размер картинки в списке (px)',
            note: 'Пример: 600x600; каждое число должно быть больше 0 и меньше 1000',
        },
        previewSizeInItem: 'Размер картинки в карточке',
        previewCustomSizeInItem: {
            title: 'Размер картинки в карточке',
            note: 'Пример: 16x9; каждое число должно быть больше 0 и меньше 1000',
        },
        defaultColumnsDesktop: 'Продуктов в строчку в списке (десктоп)',
        defaultColumnsTablet: 'Продуктов в строчку в списке (планшет)',
        defaultColumnsMobile: 'Продуктов в строчку в списке (мобильник)',
        columnsCount: 'Количество продуктов',
        shippingPolicy: 'Информация о доставке',
        refundPolicy: 'Информация о возврате',
        imageCrop: 'Метод обрезки изображений',
        imageCropVariants: {
            inset:
                'Inset - Масштабирует изображение настолько, насколько это возможно в пределах его контейнера, не обрезая и не растягивая изображение.',
            outbound:
                'Outbound - Если пропорции картинки отличаются от пропорций контейнера, изображение обрезается по вертикали',
            original: 'Original - Исходное изображение.',
        },
        skuIsShow: 'Показывать sku на сайте',
        showLeftSidebarInCatalog: 'Показывать фильтры в каталоге',
        showSortingInCatalog: 'Показывать сортировку в каталоге',
        showRelatedProducts: 'Показывать похожие товары',
        showPriceForOutOfStock: 'Показать цену на товар, которого нет в наличии',
        leadsPopupTitle: 'Заголовок всплывашки заявок',
        checkoutFormMessage: 'Собщение в оформлении заказа',
        checkoutOrderMessage: 'Собщение в чеке заказа',
        showLeadsPopupPolicy: 'Показывать политику для заявок',
        leadsPopupEnabledByDefault: 'Включено по умолчанию',
        leadsPopupPolicyText: 'Текст политики в заявке',
        showCheckoutPolicy: 'Показывать политику в форме заказа',
        checkoutPolicyEnabledByDefault: 'Включено по умолчанию',
        checkoutPolicyText: 'Текст политики в форме заказа',
        fastOrder: 'Быстрый заказ',
        showCheckoutReceiver: 'Показвать в форме другого получателя',
        pageNavigationType: 'Навигация в каталоге',
        btnLoadMore: 'Кнопка загрузить ещё',
        productWidgetRelatedPosition: 'Положение похожих товаров в корзине',
        productReviewsEnabled: 'Показывать отзывы и оценки продуктов',
        showQuantity: 'Показывать количество',
        showPriceFrom: 'Цена формата "От {цена}"',
        modalAgeVerification: 'Всплывашка подтверждения возраста',
        modalCookieAccept: 'Всплывашка подтверждения использования cookie',
        filter: 'Фильтр',
        type: 'Тип',
        productDetails: 'Характеристики продукта',
        templateInProductList: 'Шаблон в списке продуктов',
        templateInProductCard: 'Шаблон в карточке продукта',
    },
    seo: {
        value: 'SEO',
        googleAnalytics: 'Google Аналитика (ID)',
        googleVerification: 'Код верификации Google',
        robots: 'Robots.txt',
        metaPixel: 'Meta Pixel (ID)',
        yandexMetrika: 'Яндекс Метрика (ID)',
        yandexVerification: 'Яндекс Верификация',
        callTrackingMango: 'Call Tracking Mango',
        metatags: {
            heading: 'Meta Tags Templates',
            variables: 'Доступные переменные',
            h1: 'Заголовок (h1)',
            title: 'Meta-title',
            description: 'Meta-description',
            product: 'Товары',
            catalog: 'Каталоги',
            page: 'Страницы',
            category: 'Категории',
            article: 'Статьи',
        },
        redirects: {
            value: 'Редиректы',
            create: 'Создать редирект',
            fromUrl: 'С URL',
            fromFile: 'Загрузить из файла',
            toUrl: 'На URL',
            toObject: 'На объект',
            file: 'Файл',
        },
        customCode: {
            title: 'Custom Code (JS)',
            note: 'Внимание! Сторонний код может сильно повлиять на производительность и корректность работы сайта',
        },
        customCodeFooter: {
            title: 'Custom Code Footer',
            note: 'Внимание! Сторонний код может сильно повлиять на производительность и корректность работы сайта',
        },
    },
    paymentCashOnDelivery: {
        value: 'Наличными при получении',
        methodName: 'Название',
    },
    paymentYooKassa: {
        value: 'YooKassa',
        enabled: 'Включено',
        currency: 'Валюта',
        returnUrl: 'URL возврата',
        secret: 'Secret',
        shopId: 'ShopID',
        vatCode: 'Vat-код',
        methodName: 'Название',
    },
    paymentNetworkGenius: {
        value: 'NetworkGenius',
        reference: 'reference',
        secretKey: 'secretKey',
        headerToken: 'headerToken',
        language: 'Язык',
        currency: 'Валюта',
        testMode: 'Тест-мод',
        methodName: 'Название',
    },
    paymentStripe: {
        value: 'Stripe',
        apiKey: 'Апи-ключ',
        currency: 'Валюта',
        testMode: 'Тест-мод',
        methodName: 'Название',
    },
    erpFirstBit: {
        value: 'FirstBit',
        enabled: 'Активно',
        url: 'Url',
        login: 'Логин',
        password: 'Пароль',
    },
    bitrix: {
        value: 'Bitrix',
        enabled: 'Активно',
        domain: 'Домен',
        userId: 'ID пользователя',
        secret: 'Секретный ключ',
    },
    notifierEmail: {
        value: 'Email Notification Settings',
        enabled: 'Активно',
        recipients: 'Получатели',
        senderEmail: 'Email отправителя',
        senderName: 'Имя отправителя',
        addRecipient: 'Добавить получателя',
        logo: 'Логотип для писем (png)',
    },
    notifierTelegram: {
        value: 'Telegram Notification Settings',
        enabled: 'Enable',
        chatId: 'chatId',
        token: 'Токен',
        template: 'Шаблон',
        templateStandard: 'Стандратный шаблон',
        templateCustom: 'Использовать свой шаблон',
        availableVariables: 'Доступные переменные',
        clientInfo: 'Информация о клиенте',
        orderInfo: 'Информация о заказе',
        shippingAndDeliveryInfo: 'Информация о доставке',
        yourInfo: 'Информация о магазине',
        variables: {
            orderNumber: 'Номер заказа',
            name: 'Имя клиента',
            domain: 'Домен',
            email: 'Email клиента',
            phone: 'Номер телефона клиента',
            country: 'Страна',
            region: 'Регион',
            city: 'Город',
            address: 'Адрес',
            apartment: 'Квартира',
            postCode: 'Почтовый индекс',
            deliveryOrPickupAddress: 'Адрес доставки или пункта самовывоза',
            deliveryPrice: 'Стоимость доставки',
            total: 'Стоимость заказа',
            orderList: 'Список товаров в заказе',
            date: 'Дата создания заказа',
            location: 'Пункт самовывоза',
            text: 'Текст',
            copyright: 'Политика авторского права',
            logo: 'Лого вашего магазина',
        },
    },
    language: {
        value: 'Язык сайта',
        current: {
            title: 'Язык сайта',
            variants: {
                en: 'Английский',
                ru: 'Русский',
            },
        },
    },
    additional: {
        value: 'Дополнительно',
        optionsView: 'Отображение опций',
    },
};
