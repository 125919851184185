export default {
    main: {
        dashboard: 'Dashboard',
        pages: 'Pages',
        products: {
            title: 'Products',
            all: 'All Items',
            disabled: 'Disabled Items',
            options: 'Options',
            properties: 'Details',
            labels: 'Labels',
            reviews: 'Reviews ({count})',
        },
        catalogs: 'Catalogs',
        orders: 'Orders',
        menu: {
            title: 'Menu',
            info: 'Info',
            main: 'Main',
            footer: 'Footer',
            mobile: 'Mobile',
        },
        users: 'Access and Security',
        customers: 'Customers',
        settings: {
            title: 'Settings',
            customize: 'Builder',
            file_manager: 'File Manager',
            notification: 'Notification',
            domains: 'Domains',
            erp: 'ERP',
            websiteSettings: 'Website Settings',
            orderStates: 'Order Statuses',
        },
        seo: {
            title: 'SEO-modules',
            tag_manager: 'Tag manager',
            sitemap: 'Sitemap',
            robots: 'Robots.txt',
            redirects: 'Redirects',
            metatags: 'Meta Tags',
        },
        delivery: {
            title: 'Shipping and delivery',
            locations: 'Locations',
            delivery: 'Shipping and delivery',
        },
        payment: {
            title: 'Payment',
            yookassa: 'YooKassa',
        },
        billing: {
            title: 'Billing',
        },
        leads: {
            title: 'Leads',
            all: 'All leads',
        },
        blog: {
            title: 'Media',
            categories: 'Categories',
            articles: 'Articles',
            authors: 'Authors',
            tags: 'Tags',
            comments: 'Comments',
        },
    },
    user: {
        account: 'Account Setting',
        logout: 'Logout',
    },
    pageHeaders: {
        products: 'Products',
        disabledProducts: 'Disabled items',
        orders: 'Orders',
        pages: 'Pages',
        options: 'Options',
        labels: 'Labels',
        reviews: 'Reviews',
        catalogs: 'Catalogs',
        accessAndSecurity: 'Users',
        notification: 'Notification',
        domains: 'Domains',
        tagManager: 'Tag Manager',
        metaTags: 'Meta Tag Templates',
        robots: 'Robots.txt',
        redirects: 'Redirects',
        locations: 'Locations',
        shippingAndDelivery: 'Shipping and Delivery',
        payment: 'Payment',
        billing: 'Billing',
        dashboard: 'Dashboard',
        erp: 'ERP',
        leads: 'Leads',
        customers: 'Customers',
        orderStates: 'Order Statuses',
        categories: 'Categories',
        articles: 'Articles',
        authors: 'Authors',
        tags: 'Tags',
        comments: 'Comments',
        articlesInCategory: 'Articles in category «{category}»',
        properties: 'Details',
    },
    topBar: {
        back: 'Back',
        backToAdmin: 'Back to Admin',
    },
    deletionModal: {
        header: 'Delete {item}',
        deleteAllHeader: 'Delete all {item}',
        text:
            'Are you sure you want to remove this {item}? All of your data will be permanently removed. This action cannot be undone. ' +
            '| Are you sure you want to remove these {item}? All of your data will be permanently removed. This action cannot be undone.',
        deleteAllText:
            'Are you sure you want to remove all (!) uploaded {item} and clear the table? All of your data will be permanently removed. This action cannot be undone.',
        entities: {
            page: 'page | pages',
            option: 'option | options',
            catalog: 'catalog | catalogs',
            product: 'product | products',
            productVariation: 'variation | variations',
            label: 'label | labels',
            review: 'review | reviews',
            order: 'order | orders',
            location: 'location | locations',
            shippingZone: 'shipping zone | shipping zones',
            rate: 'rate | rates',
            redirect: 'redirect | redirects',
            user: 'user | users',
            image: 'image | images',
            payment: 'payment | payments',
            domain: 'domain | domains',
            orderState: 'order status | order statuses',
            lead: 'lead | leads',
            category: 'category | categories',
            article: 'article | articles',
            author: 'author | authors',
            comment: 'comment | comments',
            tag: 'tag | tags',
            property: 'detail | details',
        },
    },
    selectPlaceholder: 'Choose',
    fileInputPlaceholder: 'Click to select a file',
    auth: {
        description:
            'Create an ecommerce website backed by powerful tools that help you find customers, drive sales, and manage your day-to-day',
        login: {
            header: 'Sign in',
            password: 'Forgot your password?',
            submit: 'Sign in',
            remember: 'Remember me',
        },
        restorePassword: {
            description: 'Enter your registered email to reset your password',
            header: 'Forgot password?',
            submit: 'Submit',
            back: 'Back to sign in',
            success: {
                description: 'We have sent you a reset password link on your registered email address',
                header: 'Check your email',
            },
        },
    },
};
