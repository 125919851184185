export default {
    enabled: {
        id: null,
        position: 0,
        type: 'boolean',
        value: true,
        options: {},
    },
    allowedLocations: {
        id: null,
        position: 0,
        type: 'array',
        value: [],
        options: {},
    },
};
