<template>
    <div class="color-picker">
        <zipify-color-picker ref="picker" v-click-outside="togglePicker" v-model="model" canBeEmpty type="hex6">
            <template #activator="{ toggle }">
                <div class="color-picker__field-container" @click="toggle($event.target)">
                    <div
                        class="color-picker__field"
                        type="color"
                        :style="{ backgroundColor: hasCurrent ? current : '#000000' }"
                    />
                    {{ current }}
                </div>
            </template>
        </zipify-color-picker>
        <Button v-if="hasCurrent" class="color-picker__btn-clear" type="icon" icon="cancel" @click="clearCurrent" />
    </div>
</template>

<script>
import { debounce } from 'lodash';
import Button from '@/components/common/Button.vue';
const { ZipifyColorPicker } = require('zipify-colorpicker');

export default {
    name: 'ColorPicker',
    components: { Button, ZipifyColorPicker },
    props: {
        value: {
            type: [String, null],
            default: null,
        },
        name: {
            type: String,
        },
        group: {
            type: String,
        },
    },
    computed: {
        model: {
            get() {
                return this.value ? this.value : '';
            },
            set(value) {
                this.inputDebounced(value);
            },
        },
        current() {
            return this.model ? this.model : 'Not selected';
        },
        hasCurrent() {
            return !!this.model;
        },
    },
    methods: {
        clearCurrent() {
            this.model = null;
        },
        togglePicker() {
            this.$refs.picker.close();
        },
        inputDebounced: debounce(function(value) {
            this.$emit('input', value);
        }, 100),
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue, // TODO: зачем тут value?
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.color-picker {
    position: relative;
    border: $form-control-border;
    border-radius: $form-control-border-radius;
    background: $form-control-bg;
    transition: $form-control-transition;

    &:hover {
        border-color: var(--v-on-surface-medium-base);
    }
    &:focus-within {
        border-color: var(--v-primary-accent-base);
    }
    &__field {
        display: block;
        width: 32px;
        height: 24px;
        padding: 0;
        border-radius: 4px;
        margin-right: 8px;
        cursor: pointer;
        border: 1px solid var(--v-outline-base);
        &:focus {
            outline: none;
        }
        appearance: none;
        &-container {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            height: 40px;
            padding: 0 $form-control-padding-horizontal;
        }
    }
    &__btn-clear {
        position: absolute;
        right: 3px;
        top: 2px;
    }
    .zpc-field--single,
    .zpc-alpha-wrap,
    .zpc-presets {
        display: none;
    }
    .zpc-sliders {
        padding-bottom: 0;
    }
    .zpc {
        background-color: var(--v-surface-base);
        .zpc-field {
            .zpc-input__input {
                background-color: var(--v-surface-base);
                color: var(--v-on-surface-high-base);
                font-size: 14px;
                font-family: Inter, sans-serif;
                padding: 6px 4px;
                border-radius: 4px;
                font-weight: 400;
            }
            .zpc-input__label {
                color: var(--v-on-surface-medium-base);
                font-family: Inter, sans-serif;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
}
</style>
