import { cloneDeep, isEqual } from 'lodash';
import ProductService from '@/services/ProductService';
import CatalogService from '@/services/CatalogService';
import ProductLabelService from '@/services/ProductLabelService';
import PropertyService from '@/services/PropertyService';
import OptionService from '@/services/OptionService';
import ImageService from '@/services/ImageService';
import productService from '@/services/ProductService';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Option from '@/entities/option/Option';
import Product from '@/entities/product/Product';
import Property from '@/entities/property/Property';
const state = {
    initial: null,
    current: null,
    catalogList: null,
    newImages: [],
    optionList: null,
    propertyList: null,
    productLabelList: null,
    optionsToUpdate: { existingOptions: [], newOptions: [] },
    propertiesToUpdate: { existingProperties: [], newProperties: [] },
    catalogsToCreate: [],
    labelsToCreate: [],
    isSaveAllowed: false,
    isAvailable: true,
};

const getters = {
    getCurrent: (state) => state.current,
    getInitial: (state) => state.initial,
    getCatalogList: (state) => state.catalogList,
    getProductLabelList: (state) => state.productLabelList,
    getOptionsList: (state) => state.optionList,
    getPropertiesList: (state) => state.propertyList,
    getOptionsToUpdate: (state) => state.optionsToUpdate,
    getPropertiesToUpdate: (state) => state.propertiesToUpdate,
    getNewImages: (state) => state.newImages,
    getIsAvailable: (state) => state.isAvailable,
    getCatalogsToBeAddedTo: (state) => {
        return state.current.catalogs.filter((item) => {
            return !state.initial.catalogs.includes(item);
        });
    },
    getCatalogsToCreate: (state) => {
        return state.catalogsToCreate;
    },
    getLabelsToCreate: (state) => {
        return state.labelsToCreate;
    },
    getCatalogsToBeRemovedFrom: (state) => {
        return state.initial.catalogs.filter((item) => {
            return !state.current.catalogs.includes(item);
        });
    },
    getIsSaveAllowed: (state) => {
        return (
            !isEqual(state.current.data, state.initial.data) || !isEqual(state.current.catalogs, state.initial.catalogs)
        );
    },
};

const mutations = {
    resetState(state) {
        state.catalogsToCreate = [];
        state.labelsToCreate = [];
    },
    setInitial(state, payload) {
        state.initial = payload;
    },
    setCurrent(state, payload) {
        state.current = payload;
    },
    setNewImages(state, payload) {
        state.newImages = payload;
    },
    setCatalogList(state, payload) {
        state.catalogList = payload;
    },
    setOptonsList(state, payload) {
        state.optionList = payload;
    },
    setPropertyList(state, payload) {
        state.propertyList = payload;
    },
    setProductLabelList(state, payload) {
        state.productLabelList = payload;
    },
    setSaveAllowed(state, payload) {
        state.isSaveAllowed = payload;
    },
    setIsAvailable(state, payload) {
        state.isAvailable = payload;
    },
    setLabelsToCreate(state, payload) {
        state.labelsToCreate = payload;
    },
    setCatalogsToCreate(state, payload) {
        state.catalogsToCreate = payload;
    },
    setOptionsToUpdate(state, payload) {
        const existingOptions = state.optionList;
        state.optionsToUpdate = payload.reduce(
            (acc, item) => {
                if (existingOptions.find((existingOption) => existingOption.id === item.id)) {
                    acc.existingOptions.push(item);
                } else {
                    acc.newOptions.push(item);
                }
                return acc;
            },
            { newOptions: [], existingOptions: [] }
        );
    },
    setPropertiesToUpdate(state, payload) {
        const existingProperties = state.propertyList;
        state.propertiesToUpdate = payload.reduce(
            (acc, item) => {
                if (existingProperties.find((existingProperty) => existingProperty.id === item.id)) {
                    acc.existingProperties.push(item);
                } else {
                    acc.newProperties.push(item);
                }
                return acc;
            },
            { newProperties: [], existingProperties: [] }
        );
    },
    setCurrentsField(state, { group, name, value }) {
        if (group) {
            state.current[group][name] = cloneDeep(value);
        } else {
            state.current[name] = cloneDeep(value);
        }
    },
    setInitialsField(state, { group, name, value }) {
        if (group) {
            state.initial[group][name] = cloneDeep(value);
        } else {
            state.initial[name] = cloneDeep(value);
        }
    },
};

const actions = {
    // Load -------------------------------------------------
    async load({ commit, dispatch, getters }, id) {
        commit('resetState');
        EventEmitter.trigger('toggle-loader', { isLoading: true });
        const promises = [
            await dispatch('loadCatalogList'),
            await dispatch('loadOptionList'),
            await dispatch('loadPropertyList'),
            await dispatch('loadProductLabelList'),
        ];
        commit('setNewImages', []);
        if (id) {
            promises.push(await dispatch('loadProduct', id), await dispatch('loadProductCatalogIds', id));
        } else {
            commit('setCurrent', new Product());
        }
        await Promise.all(promises);
        const product = cloneDeep(getters.getCurrent);
        product.fields.catalogs.props.items = getters.getCatalogList;
        product.fields.labelIds.props.items = getters.getProductLabelList;
        product.fields.catalogs.value = product.catalogs;
        // если всё хорошо сюда
        commit('setCurrent', cloneDeep(product));
        commit('setInitial', cloneDeep(product));
        EventEmitter.trigger('toggle-loader', { isLoading: false });
    },
    async loadProduct({ commit }, id) {
        const [error, result] = await ProductService.getOne(id);
        if (error) {
            commit('setIsAvailable', false);
        } else {
            commit('setCurrent', new Product(result));
        }
    },
    async loadProductCatalogIds({ commit }, id) {
        const [error, result] = await ProductService.getCatalogs(id);
        if (error) {
            error.notify();
        }
        commit('setCurrentsField', { name: 'catalogs', value: result.map((catalog) => catalog.id) });
    },
    async loadCatalogList({ commit }) {
        const [error, result] = await CatalogService.getAll();
        if (error) {
            error.notify();
        }
        commit('setCatalogList', result);
    },
    async loadOptionList({ commit }) {
        const [error, result] = await OptionService.getAll();
        if (error) {
            error.notify();
        }
        commit(
            'setOptonsList',
            result.map((item) => new Option(item))
        );
    },
    async loadPropertyList({ commit }) {
        const [error, result] = await PropertyService.getAll();
        if (error) {
            error.notify();
        }
        commit(
            'setPropertyList',
            result.map((item) => new Property(item))
        );
    },
    async loadProductLabelList({ commit }) {
        const [error, result] = await ProductLabelService.getAll();
        if (error) {
            error.notify();
        }
        commit('setProductLabelList', result);
    },

    // Save -------------------------------------------------

    async createProduct({ commit, getters, dispatch }) {
        EventEmitter.trigger('toggle-loader', { isLoading: true });
        commit('setSaveAllowed', false);
        await dispatch('createNewLabelsAndCatalogs');
        const [error, result] = await ProductService.createOne(
            getters.getCurrent.data,
            getters.getOptionsToUpdate,
            getters.getCatalogsToBeAddedTo,
            getters.getNewImages,
            getters.getPropertiesToUpdate
        );
        if (!error) {
            localStorage.setItem('productCreated', true);
            commit('setNewImages', []);
            commit('setPropertiesToUpdate', []);
        } else {
            error.notify();
            commit('setSaveAllowed', true); // можно заменить на проверку доступности кнопки
        }
        EventEmitter.trigger('toggle-loader', { isLoading: false });
        return [error, result];
    },
    async updateProduct({ commit, getters, dispatch }) {
        EventEmitter.trigger('toggle-loader', { isLoading: true });
        commit('setSaveAllowed', false);
        if (getters.getNewImages.length) {
            await dispatch('uploadImages');
            commit('setNewImages', []);
        }
        await dispatch('createNewLabelsAndCatalogs');
        const [error, result] = await ProductService.updateOne(
            getters.getCurrent.data,
            getters.getOptionsToUpdate,
            getters.getCatalogsToBeAddedTo,
            getters.getCatalogsToBeRemovedFrom,
            getters.getPropertiesToUpdate
        );
        if (!error) {
            dispatch('load', getters.getCurrent.id);
            commit('setPropertiesToUpdate', []);
        } else {
            commit('setSaveAllowed', true); // можно заменить на проверку доступности кнопки
        }
        EventEmitter.trigger('toggle-loader', { isLoading: false });
        return [error, result];
    },
    async createNewLabelsAndCatalogs({ getters, dispatch }) {
        const newCatalogs = getters['getCatalogsToCreate'].map((item) => item.data);
        const cleansedCatalogs = getters.getCurrent.catalogs.filter((catalogId) => {
            return !newCatalogs.find((newCatalog) => {
                return newCatalog.id === catalogId;
            });
        });
        const catalogPromises = newCatalogs.map((item) => CatalogService.createOne(item));
        const catalogResults = await Promise.all(catalogPromises);
        const successfullyCreatedCatalogs = [];
        catalogResults.forEach((item) => {
            const [error, result] = item;
            if (error) {
                error.notify();
            } else {
                successfullyCreatedCatalogs.push(result.id);
            }
        });
        const productCatalogs = [...cleansedCatalogs, ...successfullyCreatedCatalogs];
        dispatch('updateCurrentsField', { name: 'catalogs', value: productCatalogs });
        const newLabels = getters['getLabelsToCreate'].map((item) => item.data);
        const cleansedLabels = getters.getCurrent.labelIds.filter((labelId) => {
            return !newLabels.find((newLabel) => {
                return newLabel.id === labelId;
            });
        });
        const labelPromises = newLabels.map((item) => ProductLabelService.createOne(item));
        const labelResults = await Promise.all(labelPromises);
        const successfullyCreatedLabels = [];
        labelResults.forEach((item) => {
            const [error, result] = item;
            if (error) {
                error.notify();
            } else {
                successfullyCreatedLabels.push(result.id);
            }
        });
        const productLabels = [...cleansedLabels, ...successfullyCreatedLabels];
        dispatch('updateCurrentsField', { name: 'labelIds', value: productLabels });
    },
    async cloneProduct({ commit, getters }) {
        EventEmitter.trigger('toggle-loader', { isLoading: true });
        commit('setSaveAllowed', false);
        const [error, result] = await ProductService.cloneProduct(getters.getCurrent.id);
        if (!error) {
            localStorage.setItem('productDuplicated', true);
        } else {
            error.notify();
            commit('setSaveAllowed', true); // можно заменить на проверку доступности кнопки
        }
        EventEmitter.trigger('toggle-loader', { isLoading: false });
        return result;
    },
    async uploadImages({ getters, dispatch, commit }) {
        const newImages = getters.getNewImages;
        await Promise.all(
            newImages.map((image) => ImageService.uploadOne('product', image.file, getters.getCurrent.id))
        );
        const [error, images] = await ImageService.getEntityImages('product', getters.getCurrent.id);
        if (error) {
            error.notify();
            return;
        }
        dispatch('updateCurrentsField', { name: 'images', value: images });
        //обновляем initialState, чтобы не тригерить кнопку Save
        dispatch('updateInitialsField', { name: 'images', value: images });
        commit('setNewImages', []);
    },
    // Remove -------------------------------------------------
    async removeProduct({ getters }) {
        return await productService.removeOne(getters.getCurrent.id);
    },
    async removeImage(context, id) {
        await ImageService.deleteEntityImages('product', id);
    },
    // Components Actions -------------------------------------------------
    updateCurrent({ commit }, data) {
        commit('setCurrent', data);
    },
    updateInitialsField({ commit }, { name, group, value }) {
        commit('setInitialsField', { name, group, value });
    },
    updateCurrentsField({ commit }, { name, group, value }) {
        commit('setCurrentsField', { name, group, value });
    },
    updateOptionsToUpdate({ commit }, data) {
        commit('setOptionsToUpdate', data);
    },
    updatePropertiesToUpdate({ commit }, data) {
        commit('setPropertiesToUpdate', data);
    },
    updateNewImages({ commit }, data) {
        commit('setNewImages', data);
    },
    updateCatalogsToCreate({ commit }, data) {
        commit('setCatalogsToCreate', data);
    },
    updateLabelsToCreate({ commit }, data) {
        commit('setLabelsToCreate', data);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
