export default {
    styles: {
        value: 'Styles',
        color_scheme: 'Color Scheme',
        accent: 'Accent',
        accent_secondary: 'Accent secondary',
        action_error: 'Action error',
        action_error_bg: 'Action error bg',
        action_error_hover: 'Action error hover',
        action_error_pressed: 'Action error pressed',
        action_error_text: 'Action error text',
        action_neutral: 'Action neutral',
        action_neutral_disabled: 'Action neutral disabled',
        action_neutral_hover: 'Action neutral hover',
        action_neutral_pressed: 'Action neutral pressed',
        action_neutral_text: 'Action neutral text',
        action_primary: 'Action primary',
        action_primary_disabled: 'Action primary disabled',
        action_primary_hover: 'Action primary hover',
        action_primary_pressed: 'Action primary pressed',
        action_primary_text: 'Action primary text',
        action_secondary: 'Action Secondary',
        action_secondary_disabled: 'Action secondary disabled',
        action_secondary_hover: 'Action secondary hover',
        action_secondary_pressed: 'Action secondary pressed',
        action_secondary_text: 'Action secondary text',
        // action_neutral_light: 'Action neutral light',
        // action_neutral_light_disabled: 'Action neutral light disabled',
        // action_neutral_light_hover: 'Action neutral light hover',
        // action_neutral_light_pressed: 'Action neutral light pressed',
        action_neutral_light: 'Сolor of text',
        action_neutral_light_disabled: 'Сolor of border',
        action_neutral_light_hover: 'Сolor of hover text ',
        action_neutral_light_pressed: 'Сolor of active text',
        // action_neutral_medium: 'Action neutral medium',
        // action_neutral_medium_disabled: 'Action neutral medium disabled',
        // action_neutral_medium_hover: 'Action neutral medium hover',
        // action_neutral_medium_pressed: 'Action neutral medium pressed',
        action_neutral_medium: 'Form item border',
        action_neutral_medium_disabled: 'Form item border disabled',
        action_neutral_medium_hover: 'Form item border active',
        action_neutral_medium_pressed: 'Form item border pressed',
        action_neutral_dark: 'Action neutral dark',
        action_neutral_dark_disabled: 'Action neutral dark disabled',
        action_neutral_dark_hover: 'Action neutral dark hover',
        action_neutral_dark_pressed: 'Action neutral dark pressed',
        action_success: 'Action success',
        action_success_bg: 'Action success bg',
        action_success_hover: 'Action success hover',
        action_success_pressed: 'Action success pressed',
        action_success_text: 'Action success text',
        action_warn: 'Action warn',
        action_warn_bg: 'Action warn bg',
        action_warn_hover: 'Action warn hover',
        action_warn_pressed: 'Action warn pressed',
        action_warn_text: 'Action warn text',
        color_base_invert: 'color base invert',
        color_bg_footer: 'color bg footer',
        color_bg_header: 'color bg header',
        color_bg_main: 'color bg main',
        color_bg_section: 'color bg section',
        color_bg_section_2: 'color bg section 2',
        color_bg_separator: 'color bg separator',
        color_disable: 'color disable',
        color_heading: 'color heading',
        color_heading_invert: 'color heading invert',
        color_hint: 'color hint',
        color_hint_invert: 'color hint invert',
        color_text: 'color text',
        color_text_footer: 'color text footer',
        color_text_invert: 'color text invert',
        color_text_secondary: 'color text secondary',
        color_text_secondary_invert: 'color text secondary invert',
        color_whatsapp: 'color whatsapp',
        color_whatsapp_hover: 'color whatsapp hover',
        color_whatsapp_text: 'color whatsapp text',
        // text_main: 'Text Main',
        text_main: 'Color of headline',
        text_body1: 'Main color of the content text',
        text_body2: 'Additional color of the content text',
        // text_accent: 'Text Accent',
        // text_neutral: 'Text Neutral',
        text_main_inverted: 'Color of headline in dark section',
        text_body1_inverted: 'Main color of the content text in dark section',
        text_body2_inverted: 'Additional color of the content text in dark section',
        // text_accent_inverted: 'Text Accent Inverted',
        // text_neutral_inverted: 'Text Neutral Inverted',
        accent1: 'Label text color  1',
        accent1_bg: 'Label background color 1',
        accent2: 'Label text color 2',
        accent2_bg: 'Label background color 2',
        accent3: 'Label text color 3',
        accent3_bg: 'Label background color 3',
        accent4: 'Label text color 4',
        accent4_bg: 'Label background color 4',
        success: 'Success color',
        success_bg: 'Success Background color',
        warning: 'Warning color',
        warning_bg: 'Warning Background color',
        error: 'Error color',
        error_bg: 'Error Background',
        neutral1: 'Background color 1',
        neutral2: 'Background color 2',
        neutral3: 'Background color 3',
        neutral4: 'Background color 4',
        neutral5: 'Background color 5',
        neutral6: 'Background color 6',
        neutral7: 'Background color 7',
        whatsapp: 'WhatsApp',
        content_width: 'Content width',
        border_radius_block: 'Blocks radius',
        border_radius_btn: 'Buttons radius',
        border_radius_input: 'Inputs radius',
        header_bg: 'Header background',
        header_bg_top_bar: 'Header background Top bar',
        header_bg_mobile: 'Header background mobile',
        header_main_menu_bg: 'Main menu background',
        header_main_menu_dropdown_bg: 'Main menu dropdown background',
    },
    common: {
        value: 'Common',
        description: 'Description',
        address: 'Address',
        emails: 'Emails',
        phones: 'Phones',
        sitename: 'Site name',
        showAccount: 'Show Account',
        whatsapps: 'WhatsApps',
        workTime: 'Work time',
        privacyPolicyLink: 'Privacy Policy',
        favicon: 'Favicon',
        sharedImage: 'Shared Image',
        phoneShowOnMobileHeader: 'Show phone on mobile header',
        telegramShowOnMobileHeader: 'Show Telegram on mobile header',
        whatsappShowOnMobileHeader: 'Show Whatsapp on mobile header',
        emailShowOnMobileHeader: 'Show email on mobile header',
        phoneShowOnMobileMenu: 'Show phone on mobile menu',
        telegramShowOnMobileMenu: 'Show Telegram on mobile menu',
        whatsappShowOnMobileMenu: 'Show Whatsapp on mobile menu',
        emailShowOnMobileMenu: 'Show email on mobile menu',
        phoneShowOnWidget: 'Show phone on mobile widget',
        telegramShowOnWidget: 'Show Telegram on mobile widget',
        whatsappShowOnWidget: 'Show WhatsApp on mobile widget',
        whatsappShowOnWidgetDesktop: 'Show WhatsApp on desktop widget',
        whatsappMessage: 'Whatsapp Message',
        type: 'Site type',
        types: {
            title: 'Site type',
            ecommerce: 'Ecommerce site',
            service: 'Service site',
            serviceCart: 'Service site with cart',
        },
        phonesShowOnContactsHeader: 'Show phone on header contacts',
        whatsappShowOnContactsHeader: 'Show WhatsApp on header contacts',
        emailsShowOnContactsHeader: 'Show email on header contacts',
        telegramShowOnContactsHeader: 'Show Telegram on header contacts',
    },
    socials: {
        value: 'Social',
        facebook: 'Facebook',
        instagram: 'Instagram',
        twitter: 'X',
        linkedIn: 'Linked In',
        medium: 'Medium',
        telegram: 'Telegram',
        tiktok: 'TikTok',
        vk: 'VK',
    },
    header: {
        value: 'Header',
        template: 'Template',
        contactsSize: 'Сontacts',
        contactsTextSize: 'Сontacts text size, px',
        logo: 'Logo on Desktop',
        logoMobile: 'Logo on Mobile',
        logoAlt: 'Logo ALT',
        menu: 'Main Menu Editing',
        menuOptions: 'Main Menu options',
        menuSubOptions: 'Main Sub Menu options',
        menuInfoOptions: 'Info Menu options',
        menuOptionsTextSize: 'Text size, px',
        menuOptionsTextUppercase: 'Text uppercase',
        menuOptionsTextColor: 'Text color',
        menuInfo: 'Info Menu Editing',
        contactsStyles: 'Contacts styles',
        sendEnquiry: 'Send enquiry button',
        showSearchMobile: 'Show search on mobile',
    },
    footer: {
        value: 'Footer',
        template: 'Template',
        templates: {
            1: 'Template 1',
            2: 'Template 2',
            3: 'Template 3',
            4: 'Template 4',
        },
        background: 'Background',
        logo: 'Logo',
        logoAlt: 'Logo ALT',
        copywrite: 'Copyright',
        footerText: 'Text',
        menuOptions: 'Footer Menu options',
        menuSubOptions: 'Footer Sub Menu options',
        menuOptionsTextSize: 'Text size, px',
        menuOptionsTextUppercase: 'Text uppercase',
        menuOptionsTextColor: 'Text color',
        menuColumns: 'Menu Columns',
        menu: 'Menu Editing',
    },
    ecommerce: {
        currency: 'Currency',
        value: 'Ecommerce',
        template: 'Item Template',
        templates: {
            1: 'Template 1',
            2: 'Template 2',
            3: 'Template 3',
            4: 'Template 4',
        },
        templateListing: 'Item Listing Template',
        previewSizeInListing: 'Preview size in listing (px)',
        previewCustomSizeInListing: {
            title: 'Preview custom size in listing (px)',
            note: 'Example: 600x600; every number should be greater than 0 and lesser than 1000',
        },
        previewSizeInItem: 'Preview ratio in item',
        previewCustomSizeInItem: {
            title: 'Preview custom ratio in item',
            note: 'Example: 16x9; every number should be greater than 0 and lesser than 1000',
        },
        itemPerPage: 'Item per page',
        defaultColumns: 'Items per row',
        defaultColumnsDesktop: 'Items per row in Listing (desktop)',
        defaultColumnsTablet: 'Items per row in Listing (tablet)',
        defaultColumnsMobile: 'Items per row in Listing (mobile)',
        columnsCount: 'Columns count',
        shippingPolicy: 'Shipping Policy',
        refundPolicy: 'Refund Policy',
        imageCrop: 'Image Crop Mode',
        imageCropVariants: {
            inset:
                'Inset - Scales the image as large as possible within its container without cropping or stretching the image.',
            outbound:
                'Outbound - If the proportions of the image differ from the container, the image is cropped either vertically.',
            original: 'Original - Original image.',
        },
        skuIsShow: 'Show SKU on the site',
        showLeftSidebarInCatalog: 'Show left sidebar on catalog pages',
        showSortingInCatalog: 'Show sorting of products on catalog pages',
        showRelatedProducts: 'Show related products',
        showPriceForOutOfStock: 'Show price of products that is not in stock',
        leadsPopupTitle: 'Leads Popup Title',
        checkoutFormMessage: 'Message in the checkout form',
        checkoutOrderMessage: 'Message in the receipt',
        showLeadsPopupPolicy: 'Show policy in leads',
        leadsPopupEnabledByDefault: 'Enabled By Default',
        leadsPopupPolicyText: 'Policy text in leads',
        showCheckoutPolicy: 'Show policy on checkout form',
        checkoutPolicyEnabledByDefault: 'Enabled By Default',
        checkoutPolicyText: 'Policy text in checkout form',
        fastOrder: 'Fast order',
        showCheckoutReceiver: 'Show recipient other person',
        pageNavigationType: 'Catalog Navigation Type',
        btnLoadMore: 'Button Load More',
        productWidgetRelatedPosition: 'Related Product Position',
        productReviewsEnabled: 'Show product reviews and ratio',
        showQuantity: 'Show quantity',
        showPriceFrom: 'Show price as "From {price}"',
        modalAgeVerification: 'Age Verification Modal',
        modalCookieAccept: 'Cookie Accept Modal',
        filter: 'Filter',
        type: 'Type',
        productDetails: 'Product Details',
        templateInProductList: 'Template in Listing',
        templateInProductCard: 'Template in Card',
    },
    seo: {
        value: 'SEO',
        googleAnalytics: 'Google Analytics (ID)',
        googleVerification: 'Google Verification code',
        robots: 'Robots.txt',
        metaPixel: 'Meta Pixel (ID)',
        yandexMetrika: 'Yandex Metrika (ID)',
        yandexVerification: 'Yandex Verification',
        callTrackingMango: 'Call Tracking Mango',
        metatags: {
            heading: 'Meta Tags Templates',
            variables: 'Available variables',
            h1: 'Headline (h1)',
            title: 'Meta Title',
            description: 'Meta Description',
            product: 'Products',
            catalog: 'Catalogs',
            page: 'Pages',
            category: 'Categories',
            article: 'Articles',
        },
        redirects: {
            value: 'Redirects',
            create: 'Create Redirect',
            fromUrl: 'From URL',
            fromFile: 'Add redirects from file',
            toUrl: 'To URL',
            toObject: 'To Object',
            file: 'File',
        },
        customCode: {
            title: 'Custom Code (JS)',
            note: 'Third-party code can greatly affect the performance and correctness of the site',
        },
        customCodeFooter: {
            title: 'Custom Code Footer',
            note: 'Third-party code can greatly affect the performance and correctness of the site',
        },
    },
    paymentCashOnDelivery: {
        value: 'Cash on delivery',
        methodName: 'Name',
    },
    paymentYooKassa: {
        value: 'YooKassa',
        enabled: 'Enabled',
        currency: 'Currency',
        returnUrl: 'Return URL',
        secret: 'Secret',
        shopId: 'ShopID',
        vatCode: 'Vat Code',
        methodName: 'Name',
    },
    paymentNetworkGenius: {
        value: 'NetworkGenius',
        reference: 'reference',
        secretKey: 'secretKey',
        headerToken: 'headerToken',
        language: 'Language',
        currency: 'Currency',
        testMode: 'TestMode',
        methodName: 'Name',
    },
    paymentStripe: {
        value: 'Stripe',
        apiKey: 'apiKey',
        currency: 'Currency',
        testMode: 'TestMode',
        methodName: 'Name',
    },
    erpFirstBit: {
        value: 'FirstBit',
        enabled: 'Enabled',
        url: 'Url',
        login: 'Login',
        password: 'Password',
    },
    bitrix: {
        value: 'Bitrix',
        enabled: 'Активно',
        domain: 'Domain',
        userId: 'User Id',
        secret: 'Secret',
    },
    notifierEmail: {
        value: 'Email Notification Settings',
        enabled: 'Enable',
        recipients: 'Recipients',
        senderEmail: 'Sender Email',
        senderName: 'Sender Name',
        addRecipient: 'Add recipients',
        logo: 'Logo for email (png)',
    },
    notifierTelegram: {
        value: 'Telegram Notification Settings',
        enabled: 'Enable',
        chatId: 'chatId',
        token: 'Token',
        template: 'Template',
        templateStandard: 'Standard Template',
        templateCustom: 'Use Custom Template',
        availableVariables: 'Available Variables',
        clientInfo: 'Client Info',
        orderInfo: 'Order Info',
        shippingAndDeliveryInfo: 'Shipping and Delivery Info',
        yourInfo: 'Shop Info',
        variables: {
            orderNumber: "Order's number",
            name: "Client's name",
            domain: 'Domain',
            email: "Client's email",
            phone: "Client's phone number",
            country: 'Country',
            region: 'Region',
            city: 'City',
            address: 'Address',
            apartment: 'Apartment',
            postCode: 'Postcode',
            deliveryOrPickupAddress: 'Delivery or pickup address',
            deliveryPrice: 'Delivery price',
            total: 'Total price',
            orderList: 'List of items in order',
            date: 'Date of order',
            location: 'Location',
            text: 'Text',
            copyright: 'Copyright policy',
            logo: "Your shop's logo",
        },
    },
    language: {
        value: 'Language',
        current: {
            title: 'Language',
            variants: {
                en: 'English',
                ru: 'Russian',
            },
        },
    },
    additional: {
        value: 'Additional',
        optionsView: 'Product options view',
    },
    media: {
        value: 'Media',
        postsBlock: 'Posts Page Template',
        itemsStyle: 'Posts List Style:',
        itemsStyleFields: {
            borderRadius: 'Border Radius',
            imageProportions: 'Image Proportions',
            showAuthor: 'Show Author',
            showDate: 'Show Date',
            showTags: 'Show Tags',
            showFirstWordsAsAnnotation: 'Show First 20 words post teaser',
            template: 'Template',
            textAlign: 'Text Align',
        },
        itemsLayout: 'Posts List Layout:',
        itemsLayoutFields: {
            listColumn: 'List Column',
            listAlign: 'List Align',
            gap: 'Gap',
        },
        pageStyle: 'Post Page Style:',
        pageStyleFields: {
            authorBlock: 'Author Block Style',
            commentsBlock: 'Comments Block Style',
            showAuthor: 'Show Author',
            showComments: 'Show Comments',
            showDate: 'Show Date',
            showTableOfContent: 'Show Table of Content',
        },
    },
};
