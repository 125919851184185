import { uuid } from 'vue-uuid';
import ProductVariation from '@/entities/product/ProductVariation';
import ProductOptionEntry from './ProductOptionEntry';
import SlugService from '@/services/SlugService';
import EntityImage from '@/entities/image/EntityImage';
import { getStockSummary } from '@/entities/product/ProductHelper';
import initFields from '@/entities/product/Product.fields';
import Image from '@/entities/image/Image';
import { priceFormat } from '@/helpers/filters';
import i18n from '@/plugins/i18n';
import Property from '@/entities/property/Property';

/**
 * Класс экземпляра товара
 */
export default class Product {
    static createDefaultVariation() {
        return new ProductVariation({ title: 'Default', default: true });
    }

    static getDefaultImages(products) {
        if (!products.length) return {};
        const map = {};
        products.forEach((product) => {
            const defaultVariation = product.variations.find((variation) => variation.default);
            const image = defaultVariation.image;
            if (defaultVariation.imageId) {
                map[product.id] = product.images.find((item) => item.imageId === defaultVariation.imageId)?.file;
            } else if (image?.file) {
                map[product.id] = image.file;
            } else if (image) {
                map[product.id] = image;
            } else if (product.images.length) {
                const image = product.images[0];
                map[product.id] = image.file ? image.file : image;
            }
        });
        return map;
    }

    static getDeliverySizeOrWeight(entity, variationId = null) {
        // сначала проверяем текущую вариацию
        if (variationId) {
            const variation = entity.variations.find((item) => item.id === variationId);
            const variationSize = Product.getDeliverySizeOrWeight(variation);
            if (variationSize) return variationSize;
        }
        // если в вариации не указанны размеры и вес, то берем из продукта
        const weight = entity.weight;
        const size = (entity.length * entity.height * entity.width) / 5000;
        if (weight === 0 && size === 0) return null;
        return weight > size ? weight : size;
    }

    type = 'product';

    /**
     *
     * @param {Object}  data - объект данных товара
     */
    constructor({
        id = uuid.v4(),
        title = '',
        slug = '',
        heading = '',
        description = '',
        previewDescription = '',
        text = '',
        metaTitle = '',
        metaDescription = '',
        images = [],
        stock = null,
        enabled = true,
        hidden = false,
        noIndex = false,
        variations = [],
        options = [],
        properties = [],
        labelIds = [],
        catalogs = [],
        reviewCount = 0,
        weight = null,
        height = null,
        width = null,
        length = null,
        next = null,
        prev = null,
    } = {}) {
        this.id = id;
        this.title = title;
        this.slug = slug;
        this.heading = heading;
        this.description = description;
        this.previewDescription = previewDescription;
        this.text = text;
        this.metaTitle = metaTitle;
        this.metaDescription = metaDescription;
        //TODO: косталь, пока не унифицировали картинки для getAll() и getOne()
        this.images = images[0]?.imageId
            ? images.map((image) => new EntityImage(image))
            : images.map((image) => new Image(image));
        this.enabled = enabled;
        this.hidden = hidden;
        this.noIndex = noIndex;
        this.stock = stock;
        this.labelIds = labelIds;
        this.variations = variations.map((item) => new ProductVariation(item));
        this.options = options.map((item) => new ProductOptionEntry(item));
        this.properties = properties.map((item) => new Property(item, true));
        this.catalogs = [];
        this.slugDisabled = !!this.slug;
        this.redirect = null;
        this.reviewCount = reviewCount;
        this.catalogs = catalogs;
        if (!this.variations.length) this.variations.unshift(Product.createDefaultVariation());
        this.width = width;
        this.height = height;
        this.length = length;
        this.weight = weight;
        this.next = next;
        this.prev = prev;
        this.fields = initFields(this);
    }

    get data() {
        return {
            id: this.id,
            title: this.title,
            slug: this.slug,
            heading: this.heading,
            description: this.description,
            previewDescription: this.previewDescription,
            text: this.text,
            metaTitle: this.metaTitle,
            metaDescription: this.metaDescription,
            images: this.images.map((image) => {
                return { id: image.id, imageId: image.imageId, position: image.position };
            }),
            enabled: this.enabled,
            hidden: this.hidden,
            noIndex: this.noIndex,
            labelIds: this.labelIds,
            variations: this.variations.map((variation) => variation.data),
            options: this.options.map((option) => option.data),
            properties: this.properties.map((property) => property.data),
            redirect: this.redirect?.data,
            width: this.width,
            height: this.height,
            length: this.length,
            weight: this.weight,
        };
    }

    async changeSlugFromSlug(text) {
        const [error, result] = await SlugService.changeSlugFromSlug(text, this);
        if (error) {
            error.notify();
            return;
        }
        this.slug = result;
        this.slugChanged = true;
    }

    get size() {
        return { length: this.length, width: this.width, height: this.height };
    }

    get defaultVariation() {
        return this.variations.find((item) => item.default);
    }

    getListPrice(currency) {
        const prices = [];
        this.variations.forEach((item) => {
            if (item.discountPrice) {
                prices.push(item.discountPrice);
            }
        });
        switch (prices.length) {
            case 0:
                return i18n.t('lists.text.priceOnRequest');
            case 1:
                return priceFormat(prices[0], currency);
            default: {
                const minPrice = priceFormat(Math.min(...prices), currency);
                const maxPrice = priceFormat(Math.max(...prices), currency);
                return `${minPrice} - ${maxPrice}`;
            }
        }
    }
    get stockSummary() {
        return getStockSummary(this);
    }

    get isSizeSpecified() {
        return this.width && this.height && this.length;
    }
}
